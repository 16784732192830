define('ember-set-body-class/services/body-class', ['exports', 'ember-set-body-class/util/get-dom'], function (exports, _getDom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    register: function register(component) {
      this._dom = (0, _getDom.default)(component);
      this.get('components').addObject(component);
    },
    deregister: function deregister(component) {
      this.get('components').removeObject(component);
    },


    components: Ember.computed(function () {
      return Ember.A();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('names');
    },


    names: Ember.computed('components.@each.name', function () {
      return this.get('components').map(function (c) {
        return c.get('name') ? String(c.get('name')).split(/\s+/) : [];
      }).reduce(function (a, b) {
        return Ember.A(a.concat(b));
      }, Ember.A()).uniq();
    }),

    scheduleUpdate: Ember.observer('names.[]', function () {
      this.scheduledRun = Ember.run.once(this, this.updateBodyClass);
    }),

    updateBodyClass: function updateBodyClass() {
      if (!this._dom) {
        return;
      }

      var body = this._dom.body;
      var attr = body.getAttribute('class');
      var classList = Ember.A(attr ? attr.split(/\s+/) : []);

      classList.removeObjects(this._previousNames || []);
      classList.addObjects(this.get('names'));

      this._previousNames = this.get('names');

      body.setAttribute('class', classList.join(' '));
    },
    willDestroy: function willDestroy() {
      Ember.run.cancel(this.scheduledRun);
    }
  });
});
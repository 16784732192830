define('ember-set-body-class/components/set-body-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    bodyClass: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.get('bodyClass').register(this);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('bodyClass').deregister(this);
      this._super.apply(this, arguments);
    }
  }).reopenClass({
    positionalParams: ['name']
  });
});
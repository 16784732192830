define("ember-tooltips/templates/components/ember-tooltip-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xh11XzF3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"_awaitingTooltipElementRendered\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"_shouldRenderContent\"]]],null,{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"        \"],[14,1,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,0,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"in-element\",[[23,0,[\"_renderElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"_shouldRenderContent\"]]],null,{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"        \"],[14,1,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,0,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-tooltips/templates/components/ember-tooltip-base.hbs"
    }
  });

  _exports.default = _default;
});